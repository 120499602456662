import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  nachricht: '',
  telefon: '',
}
export const Contact = (props) => {
  const [{ name, email, nachricht, telefon }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, nachricht, telefon)
    emailjs
      .sendForm(
        'service_y0l1thg', 'template_3hjagcm', e.target, 'user_UgEZhOU5XMwpLZiU1qfhP'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
      e.target.reset()
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contact us</h2>
                <p>
                Aroused interest? Then simply contact us using the contact form below or give us a call
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                    
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='telefon'
                        id='telefon'
                        name='telefon'
                        className='form-control'
                        placeholder='telephone'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                    
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='nachricht'
                    id='nachricht'
                    className='form-control'
                    rows='6'
                    placeholder='Let us know when you would like to spend your holiday in the villa'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-5 col-md-offset-1 contact-info'>
            <div>
            <div className='contact-item'>
              <h3>Contact Info</h3>

              </div>
              
              <div className="mapouter">
                <div className="gmap_canvas embed-responsive-item">
                  <iframe title="Map" width="430" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=39.6152000,%2019.8439300&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                  </iframe>
                </div>
              </div>
            

              <p>
                <span style={{ paddingTop: "2rem" }}>
                  <i className='fa fa-map-marker'></i> Adress
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> E-Mail
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>

            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-book'></i> Booking.com
                </span>{' '}
                <p>https://bit.ly/3gC8vQF</p>
              </p>

            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.booking : '/'}>
                      <i className='fa fa-book'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            <h3>Impressum</h3>
            <ul>
              <li>
              FGD Korfu
              </li>
              <li>
                Villa Marilena
              </li>
              <li>
              Agios Ioannis Triklino
              </li>
              <li>
              49100 Kerkyra
              </li>
            </ul>
            <ul>
            <li>
            Owner: Dimitrios Kourkoulos
              </li>
              <li>
              E-Mail: fgd.korfu@gmail.com
              </li>
              <li>
              Telefon: +306947793794
              </li>
            </ul>
            {' '}
          </p>
        </div>
      </div>
    </div>

    
  )
}
