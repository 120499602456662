export const photos = [
    
    {
        src: "img/villaPhotographer/DSC_4627-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4612-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4576.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4602-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4588.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4563.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4468-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4428-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4408-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4403-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4333-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4550.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4314-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4279-HDR.jpg",
        width: 4.5,
        height: 3
    },
    {
        src: "img/villaPhotographer/DSC_4269-HDR.jpg",
        width: 4.5,
        height: 3
    },



    {
        src: "img/villamarilena/sight1.jpg",
        width: 7,
        height: 7
    },
    {
        src: "img/villamarilena/sight8.jpg",
        width: 5,
        height: 7
    },
    {
        src: "img/villamarilena/sight6.jpg",
        width: 6,
        height: 7
    },
    {
        src: "img/villamarilena/strand.jpg",
        width: 6,
        height: 7
    },
    {
        src: "img/Korfu_sights.jpg",
        width: 4,
        height: 4
    }
    
];