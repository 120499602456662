import { aboutusphotos } from "../data/aboutusphotos";
import PhotoGallery from "react-photo-gallery";
import React, { useState, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import photos from "../data/aboutusphoto.json"

export const About = (props) => {
 

  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <h2 style={{ textAlign: "center" }}>About us</h2>


          <div className='col-xs-6 col-md-4'>
            {' '}
            {/*<PhotoGallery photos={photoWoman} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <div style={{ justifyContent: "center" }}>
                    <Carousel
                      currentIndex={currentImage}
                      views={photoWoman.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                      }))}
                    />
                  </div>
                </Modal>
              ) : null}
                    </ModalGateway>*/}
            <img src='img/villamarilena/marilena.jpg' className='img-responsive' style={{ width: "300px", marginLeft: "80px" }} alt='' />{' '}
          </div>
          <div className='col-xs-6 col-md-7'>
            <div className='about-text'>

              <p style={{ paddingLeft: "4rem", paddingRight: "8rem" }}>{props.data ? props.data.paragraph : 'loading...'}</p>
            </div>
          </div>
        </div>

        <div className='row' style={{ paddingTop: "8rem" }}>
          <div className='col-xs-6 col-md-6'>
            <p style={{ paddingLeft: "8rem" }}>{props.data ? props.data.paragraph2 : 'loading...'}</p>
          </div>

          <div className='col-xs-6 col-md-6'>
            {' '}
            <img src='img/marilena_outside.jpg' className='img-responsive' style={{ width: "300px", marginLeft: "80px" }} alt='' />{' '}
          </div>
        </div>


        <div className='row' style={{ paddingTop: "8rem" }}>

          <div className='col-xs-6 col-md-5'>
            {' '}
            <img src='img//Korfu_sights.jpg' className='img-responsive' style={{ width: "300px", marginLeft: "80px" }} alt='' />{' '}
          </div>
          <div className='col-xs-6 col-md-7'>
            <div className='about-text'>

              <p style={{ paddingRight: "11rem", paddingLeft: "4rem" }}>{props.data ? props.data.paragraph3 : 'loading...'}</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}
