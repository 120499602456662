export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='#contact'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Kontakt
                </a>{' '}
                
                <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/FerienaufKorfu">
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/fgd_korfu_ferienhaeuser/">
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.booking.com/hotel/gr/villa-marilena-ag-ioannis.de.html?aid=964694&app_hotel_id=6045367&checkin=2020-05-25&checkout=2020-05-31&from_sn=android&group_adults=3&group_children=0&label=Share-K3jwWF%401589879043&no_rooms=1&req_adults=3&req_children=0&room1=A%2CA%2CA">
                      <i className='fa fa-book'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
