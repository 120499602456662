export const Services = (props) => {
  return (
    <>
      <div id='furnishing' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Furnishing</h2>
            <div className="col-col-xs-6 col-md-12">
            <img src="img/facilities_leftside.png"></img>
            <img src="img/facilities_middle.png"></img>
            <img src="img/facilities_rightside.png"></img>
           
            </div>
            
          </div>
        </div>
      </div>

    </>
  )
}




